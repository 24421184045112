import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { bgImgFromData } from "../../lib/helpers";
import { jarallax } from "jarallax";
// import axios from "axios";
// import { API } from "../../config/urls";

const BannerOne = ({data}) => {
  // const [data, setData] = useState();
  // const GetBanner = () => {
  //   var config = {
  //     method: "get",
  //     url: API.getBanner,
  //     headers: {},
  //   };
  //   axios(config)
  //     .then(function (response) {
  //       console.log(response);
  //       setData(response.data.block);
  //     })

  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // };

  // useEffect(() => {
  //   GetBanner();
  // }, []);
  useEffect(() => {
    bgImgFromData();
  }, []);

  // jarallax
  useEffect(() => {
    jarallax(document.querySelectorAll(".jarallax"), {
      speed: 0.2,
    });
  }, []);

  return (
    <section
      className="banner-area jarallax banner-bg"
      data-background="/img/banner/bg1.jpg"
    >
      <div className="container">
        <div className="row justify-content-between ">
          <img
            src="/img/team/doctor.png"
            // src={imgconvert(data?.image?.[0]?.image)}
            style={{ height: "560px", width: "auto" }}
            alt=""
          />
          <div className="col-lg-7">
            <div className="banner-content">
              <span className="sub-title wow fadeInUp" data-wow-delay=".2s">
                Telehealth Services
              </span>

              <h2 className="title wow fadeInUp" data-wow-delay=".4s">
                {data?.heading}
              </h2>

              <div
                className=" fs-6"
                dangerouslySetInnerHTML={{ __html: data?.description }}
              ></div>
              <div className="banner-btn">
                <Link
                  to="/packages"
                  className="btn wow fadeInLeft"
                  data-wow-delay=".8s"
                >
                  Sign Up Now
                </Link>
                {/* <Link
                  to="/services"
                  className="btn btn-two wow fadeInRight"
                  data-wow-delay=".8s"
                >
                  Our service
                </Link> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BannerOne;
