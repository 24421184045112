import React from "react";
import FaqItem from "./FaqItem";
// import axios from "axios"
// import { API } from "../../config/urls";

const FaqAreaOne = ({data}) => {
  // const [data,setData] = useState()
  // const GetFaq = () => {
  //   var config = {
  //     method: "get",
  //     url: API.getfaqs,
  //     headers: {},
  //   };
  //   axios(config)
  //     .then(function (response) {
  //       console.log(response);
  //       setData(response.data.child_block?.[0]?.child_block)
  //     })

  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // };

  // useEffect(() => {
  //   GetFaq()
  // }, []);
  return (
    <section className="faq-area pt-90  overflow-hidden">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="faq-content">
              <div className="section-title-two mb-40 tg-heading-subheading animation-style2">
                <span className="sub-title">Our Company FAQs</span>
                <h2 className="title tg-element-title">
                  Frequently Asked <br />
                  Question from Our Clients
                </h2>
              </div>

              <div className="accordion" id="accordionExample">
                {data?.map((x, index) => (
                  <FaqItem key={index} parentId={"accordionExample"} item={x} />
                ))}
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-8">
            <div className="faq-img">
              <img src="/img/telehealth/standingnurse3.jpg" data-aos="fade-left" style={{height:"700px"}} alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FaqAreaOne;
