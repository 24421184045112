// import React from "react";
// import { Link } from "react-router-dom";

// const TeamOneItem = ({ item, index }) => {
//   return (
//     <div className="team-item">
//       <div className="team-thumb">
//         <Link to={"/doctor-details/" + index}>
//           <img
//             src={"/img/telehealth/doctor3.jpg"}
//             style={{ width: "100%" }}
//             alt=""
//           />
//         </Link>
//       </div>

//       <Link to={"/doctor-details/" + index}>
//         <div className="team-content">
//           <h3 className="title">
//             {item.first_name} {item.last_name}
//           </h3>
//           <span>{item.speciality}</span>
//         </div>
//       </Link>
//     </div>
//   );
// };

// export default TeamOneItem;




import React from 'react';
import { Link } from 'react-router-dom';

const TeamOneItem = ({ item, index }) => {
  return (
    <div className="card team-item">
      <div className="card-body d-flex">
        <div className="profile-img">
          <img src="/img/telehealth/doctor3.jpg" alt={`${item.first_name} ${item.last_name}`} className="img-fluid rounded-circle" />
        </div>
        <div className="info ml-3">
          <h5 className="card-title">{item.first_name} {item.last_name}, MD</h5>
          <p className="card-text"><i className="fas fa-stethoscope"></i> {item.speciality}</p>
          <div className="rating">
            {Array.from({ length: 5 }, (_, i) => (
              <i key={i} className={`fa${i < item.rating ? 's' : 'r'} fa-star`}></i>
            ))}
          </div>
          <div className="actions mt-2">
          <Link to={"/doctor-details/" + index}>
            <button className="btn btn-outline-primary">View Profile</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamOneItem;
