import {  useNavigate } from "react-router-dom";
// import { Steps } from "antd";
import React from "react";
// const { Step } = Steps;
export default function Accontcreatedmain() {
  const navigate = useNavigate();
  return (
    <>
      <div className="row container m-auto">
        <div
          className="col-md-7"
          style={{ backgroundColor: "", paddingTop: 170 }}
        >
          <div style={{ maxWidth: 600, margin: "auto" }}>
            <h3 className="fw-bold py-4 text-start ">Payment sucessful</h3>
            <div className=" text-start ">
              Thank you for choosing telhealth. Your membership has been
              created. To activate your account follow the instructions on your
              email account.
            </div>
            {/* <div className="py-5">
              {" "}
              <Steps current={2} size="small">
                <Step
                  title="Finished"
                  description={"Payment has been recieved."}
                />
                <Step
                  title="In Progress"
                  description={"Membership has been created."}
                />
                <Step
                  title="Waiting"
                  description={
                    "Account Activation. Follow instruction on your email."
                  }
                />
              </Steps>
            </div> */}

            <div className="">
              {/* <button
                onClick={() => {
                  navigate("/howitworks");
                }}
                className="btn homebtn  vertical-align-middle float-start"
              >
                Learn More
              </button> */}
              <button
                onClick={() => {
                  navigate("/");
                }}
                className="btn homebtn  vertical-align-middle  float-start"
                style={{ marginTop: 20 }}
              >
                Back Home
              </button>
            </div>
          </div>
        </div>
        <div style={{ paddingTop: 70 }} className="col overflow-hidden">
          <img
            height={500}
            className={""}
            src="https://niceillustrations.com/wp-content/uploads/2021/03/Successful-Payment-color-800px.png"
            alt=""
          />
        </div>
      </div>
    </>
  );
}
