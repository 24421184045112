import axios from "axios";
import React, { useState } from "react";
import { API } from "../../../config/urls";
import toast from "react-hot-toast";
import { ClipLoader } from "react-spinners";

const override = {
  display: "block",
  margin: "30px auto 0px",
  borderColor: "blue",
};
const TeamDetailsForm = ({doctorId}) => {
  const [input,setInput] = useState({
    name: "",
    email: '',
    phone: '',
    date: '',
    time: '',
    message: '',
    doctor: 6
  })

  const [loading,setLoading] = useState(false)
  const handleChange = (e) => {
    setInput(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }))
  }

  const handleSubmit = (e) => {
    setLoading(true)
    e.preventDefault()
    axios.post(API.postAppointment,input).then((res) => {
      setLoading(false)
      toast.success("Form submitted successfully")
      setInput({name: "", email: "", phone: "", date: "",time: "", message: ""})
    }).catch(err => {
      setLoading(false)
      toast.error("Appointment not submitted")
    })
  }
  return loading ?  <div style={{ height: 200, margin: "auto" }}>
  <ClipLoader
    color={"blue"}
    loading={true}
    cssOverride={override}
    size={150}
    aria-label="Loading Spinner"
    data-testid="loader"
  />
</div> : (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-md-6">
          <div className="form-grp">
            <input type="text" value={input.name} name="name" onChange={handleChange} placeholder="Your Name" />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-grp">
            <input type="email" value={input.email} name="email" onChange={handleChange} placeholder="Email address" />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-grp">
            <input type="text" value={input.phone} name="phone" onChange={handleChange} placeholder="Phone number" />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-grp">
            <input type="date" value={input.date} name="date" onChange={handleChange} placeholder="Date" />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-grp">
            <input type="time" value={input.time} name="time" onChange={handleChange} placeholder="Time" />
          </div>
        </div>
      </div>
      <div className="form-grp">
        <textarea name="message" value={input.message} onChange={handleChange} placeholder="Write message"></textarea>
      </div>
      <button type="submit" className="btn btn-two">
        Submit
      </button>
    </form>
  );
};

export default TeamDetailsForm;
