import React from "react";
import { Link } from "react-router-dom";
// import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
// import Iframe from "react-iframe";

const IntroductionAreaOne = ({ data }) => {
  return (
    <section id="intro" className="introduction-area pb-130">
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-lg-6 col-md-9">
            <div className="introduction-img-wrap">
              <img src="/img/telehealth/live.jpg" alt="" />
              <img
                src="/img/telehealth/nurse.jpg"
                data-aos="fade-right"
                alt=""
              />

              {/* <Popup
                trigger={
                  <div className="play-btn">
                    <a
                      href="#intro"
                      className="popup-video"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fas fa-play"></i>
                    </a>
                  </div>
                }
                position=""
                modal={true}
              >
                <Iframe
                  url="https://www.youtube.com/embed/XMWYZ-uZjnQ"
                  width="100%"
                  height="350px"
                  id=""
                  className=""
                  display="block"
                  position="relative"
                />
              </Popup> */}
            </div>
          </div>

          <div className="col-lg-6">
            <div className="introduction-content">
              <div className="section-title-two mb-20 tg-heading-subheading animation-style2">
                <span className="sub-title">{data?.heading}</span>
                <h2 className="title tg-element-title">
                  Welcome to Best <br />
                  Telehealth Company
                </h2>
              </div>

              <p className="info-one">
                <div
                  style={{ lineHeight: "2.5rem" }}
                  className="h6 opacity-75 text-start"
                  dangerouslySetInnerHTML={{
                    __html: data && data.description,
                  }}
                ></div>
              </p>
              <p>
              </p>

              <div className="introduction-list">
                <ul className="list-wrap">
                  {data?.custom_fields?.map((x, index) => (
                    <li>
                    <i className="fas fa-check-circle"></i>{x.text}
                  </li>
                  ))}
                </ul>
              </div>

              <div className="introduction-bottom">
                <Link to="/about" className="btn">
                  Discover MORE
                </Link>

                <span className="call-now">
                  <i className="fas fa-phone-alt"></i>
                  <a href={`tel:${data?.custom_url?.[0]?.link}`}>{data?.custom_url?.[0]?.link}</a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default IntroductionAreaOne;
