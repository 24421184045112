import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Paymentmain from "./paynow/main";
import { useContext } from "react";
import { Packages } from "../main";

const Result = (props) => {
  const [state, actions] = useContext(Packages);
  const Navigate = useNavigate();
  // const [paymentpageacess, setpaymentpageacess] = useState(false);
  // const [paymentdisplay, setpaymentdisplay] = useState("none");
  const [load, setload] = useState(false);
  // const paymentmode = () => {
  //   {
  //     setpaymentpageacess(true);
  //   }
  //   console.log(paymentpageacess);
  // };
  const promise = "pk_test_51OjhiIHE9uKMiE4yNYjdD4SzDiLb81uydJvoWMIDS3vby4a8dAL88hGkKwwT6i7xvYP2cSqzN081yXoLAeE47nce008wMsEjaT"
  // useEffect(() => {
    // const axios = require("axios");

    // let config = {
    //   method: "get",
    //   url: process.env.REACT_APP_APPURL + "external/publish_key",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Accept: "application/json",
    //   },
    // };

    // axios(config)
    //   .then((response) => {
    //     setpromise(response.data.publish_key);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  //   setpromise("pk_test_51OjhiIHE9uKMiE4yNYjdD4SzDiLb81uydJvoWMIDS3vby4a8dAL88hGkKwwT6i7xvYP2cSqzN081yXoLAeE47nce008wMsEjaT")
  // }, []);

  useEffect(() => {
    state.package_tiers?.[0]?.yearly_retail_price === undefined
      ? Navigate("/packages")
      : setload(true);
  }, [state.package_tiers, Navigate]);
  return load ? (
    <div>
      {(state.paymentdisplay === undefined || state.paymentdisplay) ? <></> : <Paymentmain promise={promise} />}

      <div style={{ minWidth: 700 }} className="pt-5  m-auto">
        <div className="container">
          <div className=" stepsbg row text-start fs-5 py-3 text-center m-0 ">
            <span className="col p-0 fs-5 ps-3 text-start p-e-1 ">
              <i className="bi bi-check-circle-fill"></i>
            </span>
            <span className="col-8 p-0 fs-6 text-center ">
              <span className="text-center fs-6">
                Package:{" "}
                <span className="fw-light fs-5">
                  {state.title} $
                  {state.terms === "yearly" ? (
                    <>
                      {state &&
                        state.package_tiers?.[0]?.yearly_retail_price.toFixed(
                          2
                        )}
                    </>
                  ) : (
                    <>
                      {(
                        state &&
                        state.package_tiers?.[0]?.yearly_retail_price / 12
                      ).toFixed(2)}
                    </>
                  )}{" "}
                  {state.terms === "monthly" ? "/MO" : "/YR"}
                </span>
              </span>
            </span>
            <span className="col-2 p-0 pe-3">
              <button
                style={{ fontSize: "10px", padding: "6px" }}
                onClick={() => Navigate("/packages/")}
                className="editplanbtn checkoutbtn float-end border-white rounded-pill m-0"
              >
                Edit Plan
              </button>
            </span>
          </div>

          <div
            style={{ borderRadius: "0px" }}
            className=" stepsbg row fs-5 m-0 pb-2 pt-1 text-center"
          >
            <strong className="col pt-2 p-0 text-start ps-3">
              <i className="bi bi-check-circle-fill"></i>
            </strong>
            <div className="col-8  ">
              <div className="border border-white mb-2 "></div>Costumer
              Information
            </div>
            <div className="col text-end pt-2">
              <button
                style={{ fontSize: "10px", padding: "6px" }}
                onClick={() => Navigate("/packages/step2")}
                className="editplanbtn checkoutbtn float-end border-white rounded-pill m-0"
              >
                Edit Info
              </button>
            </div>
          </div>
          <div className="bg-light">
            <div
              style={{ color: "var(--tg-blue)" }}
              className="fs-4 m p-3  text-center"
            >
              <strong className="float-start">3</strong>
              <span className="fw-bold">Review Information</span>
            </div>

            <div className="container w-50">
              <div
                style={{
                  borderTop: "2px solid var(--tg-blue)",
                  borderBottom: "2px solid var(--tg-blue)",
                }}
                className="container p-3 text-center"
              >
                <div
                  style={{
                    borderRadius: "29px",
                    backgroundColor: "var(--tg-blue)",
                    color: "white",
                    fontWeight: "600",
                  }}
                  className="p-4 fs-6"
                >
                  <div className="py-1">
                    {state.Firstname} {state.middle_name} {state.Lastname}
                  </div>
                  <div className="py-1">
                    {state.Gender} {state.Dob}
                  </div>
                  <div className="py-1">
                    {state.Email} {state.Mobileno}
                  </div>
                  <div className="py-1">
                    {state.Address}, {state.City}, {state.State}, {state.Zip}
                  </div>
                </div>
              </div>
            </div>

            <div className="container w-50 text-center">
              <div style={{ color: "var(--tg-blue)" }} className="fw-bold py-3 fs-3 ">
                Selcted Package
              </div>
              <div
                style={{
                  backgroundColor: "white",
                  borderRadius: "29px",
                 border: "1px solid #d0dbf8",
                  width: "250px",
                  // boxShadow: "0 1rem 4rem var(--tg-blue)",
                }}
                className="container p-0  "
              >
                <div
                  className="w-100 p-2  text-white fw-bold p-3"
                  style={{
                    backgroundColor: "var(--tg-blue)",
                    borderRadius: "29px 29px 0px 0px",
                    letterSpacing: "2px",
                  }}
                >
                  {state.terms === "yearly" ? "Yearly" : "Monthly"} Plan
                </div>
                <div className="p-2">
                  <div className="mt-0 p-0" style={{ fontSize: "60px" }}>
                    $
                    {state.terms === "yearly" ? (
                      <>
                        {Number(
                          (
                            state.package_tiers?.[0]?.yearly_retail_price -
                            (state.package_tiers?.[0]?.yearly_retail_price % 1)
                          ).toFixed(0)
                        )}
                        <sup className="fs-1">
                          .
                          {Number(
                            (state.package_tiers?.[0]?.yearly_retail_price %
                              1) *
                              100
                          ) === 0
                            ? "00"
                            : Number(
                                (
                                  (state.package_tiers?.[0]
                                    ?.yearly_retail_price %
                                    1) *
                                  100
                                ).toFixed(0)
                              )}
                        </sup>
                      </>
                    ) : (
                      <>
                        {Number(
                          (
                            state.package_tiers?.[0]?.yearly_retail_price / 12 -
                            ((state.package_tiers?.[0]?.yearly_retail_price /
                              12) %
                              1)
                          ).toFixed(0)
                        )}
                        <sup className="fs-1">
                          .
                          {Number(
                            ((state.package_tiers?.[0]?.yearly_retail_price /
                              12) %
                              1) *
                              100
                          ) === 0
                            ? "00"
                            : Number(
                                (
                                  ((state.package_tiers?.[0]
                                    ?.yearly_retail_price /
                                    12) %
                                    1) *
                                  100
                                ).toFixed(0)
                              )}
                        </sup>
                      </>
                    )}
                    <span className="fs-6">
                      {state.terms === "monthly" ? "/MO" : "/YR"}
                    </span>
                  </div>
                  <div className="pt-2 text-uppercase fw-bold fs-5  ">{state.title}</div>

                  {/* <div className="greentxt fw-bold pb-3">Total</div> */}
                </div>
              </div>
              <button
                className="editplanbtn  btn rounded-pill fw-bold py-2 my-3"
                onClick={() => {
                  Navigate("/packages/")
                  actions({ ...state, change_my_plan: true })
                }}
              >
                Change My Plan
              </button>
            </div>
          </div>
          <div
            style={{ borderRadius: "0px 0px 29px 29px" }}
            className="stepsbg py-2 fw-blod"
          >
            <div style={{ height: 52 }}>
              <button
                style={{ letterSpacing: "1px" }}
                className="btn editplanbtn  float-start ms-4 rounded-pill fw-bold fs-6 "
                onClick={() => Navigate(-1)}
              >
                back
              </button>
              <button
                className="btn editplanbtn  float-end me-4 rounded-pill fw-bold  "
                onClick={() => {
                  state.id === undefined ? (
                    <></>
                  ) : (
                    actions({ ...state, ...{ paymentdisplay: false } })
                  );
                }}
              >
                Pay Now
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default Result;
