import React, { useEffect, useState } from "react";
import BannerOne from "../../components/Banner/BannerOne";
// import BlogAreaTwo from "../../components/Blogs/BlogAreaTwo";
// import BrandArea from "../../components/BrandArea/BrandArea";
import CounterAreaTwo from "../../components/CounterAreas/CounterAreaTwo";
import FaqAreaOne from "../../components/FaqArea/FaqAreaOne";
import IntroductionAreaOne from "../../components/Introduction/IntroductionAreaOne";
// import ProjectAreaTwo from "../../components/ProjectAreas/ProjectAreaTwo";
import ServicesAreaThree from "../../components/ServicesArea/ServicesAreaThree";
import ServicesAreaTwo from "../../components/ServicesArea/ServicesAreaTwo";
import TestimonialAreaTwo from "../../components/Testimonials/TestimonialAreaTwo";
import Layout from "../../layouts/Layout";
import { gsapTitleAnimation } from "../../lib/gsap-lib/gsapTitleAnimation";
import { localURL } from "../../config/urls";
import { useLocation } from "react-router-dom";
import axios from "axios";
import SEO from "../../helpers/seo";
import { imgconvert } from "../../helpers/imgconverter";

// import Messaging from "../components/messaging";

const HomeOne = () => {
  const { pathname } = useLocation();
  console.log(pathname);

  const [data, setData] = useState();
  // const [docs, setDocs] = useState();
  useEffect(() => {
    // const GetDocs = () => {
    //   var config = {
    //     method: "get",
    //     url: localURL + "/api/v1/cms/docs/",
    //     headers: {},
    //   };
    //   axios(config)
    //     .then(function (response) {
    //       console.log(response);
    //       setDocs(response.data);
    //     })

    //     .catch(function (error) {
    //       console.log(error);
    //     });
    // };
    const Getdata = () => {
      var config = {
        method: "get",
        url: localURL + "/api/v1/cms/content/homepage/",
        // url: localURL + "/api/v1/cms/content" + pathname + "/",
        headers: {},
      };
      axios(config)
        .then(function (response) {
          console.log(response);
          setData(response.data);
        })

        .catch(function (error) {
          console.log(error);
        });
    };
    Getdata();
    // GetDocs();
  }, [pathname]);

  useEffect(() => {
    gsapTitleAnimation();
  }, []);
  console.log();

  return (
    <>
     <SEO
        title={data && data.seo && data.seo.title}
        description={data && data.seo && data.seo.description}
        image={imgconvert(
          data && data.seo && data.seo.image
        )}
        name={data && data.seo && data.seo.title}
        type={data && data.seo && data.type}
      />
      <Layout
        header={1}
        footer={1}
        headerClassName={"header-style-two"}
        topHeaderClassName={"header-top-two"}
      >
        <BannerOne data={data?.block?.[2]} />
        {/* <Messaging /> */}
        <ServicesAreaTwo
          data={data?.block?.[0]?.child_block?.[0]?.child_block}
        />
        <IntroductionAreaOne data={data?.block?.[3]} />
        <ServicesAreaThree
          data={data?.block?.[0]?.child_block?.[0]?.child_block}
        />
        <FaqAreaOne data={data?.block?.filter(item => item?.faqs)?.[0]?.child_block?.[0]?.child_block} />
        <TestimonialAreaTwo
          data={data?.block?.filter(item => item?.testimonials)?.[0]?.child_block?.[0]?.child_block}
          className={"testimonial-area-two"}
          headerClassName={"animation-style2"}
          src={"/img/bg/testimonial_bg.jpg"}
        />
            {/* <Detail data={data} /> */}
        {/* <ProjectAreaTwo /> */}
        <CounterAreaTwo
          className={"counter-area-two"}
          src={"/img/telehealth/bg.jpg"}
        />
        {/* <BlogAreaTwo /> */}
        {/* <BrandArea /> */}
      </Layout>
    </>
  );
};

export default HomeOne;
