import React from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import { FaGift, FaMedal } from "react-icons/fa6";
import { AiFillThunderbolt } from "react-icons/ai";

const PricingItem = ({ item, index }) => {
  const facilities = index === 0 ? [
    "Remote Consultations",
    "Prescription Refills",
    "Health Education",
    "Limited Follow-up Support"
  ] : index === 1 ? [
    "Remote Consultations",
    "Prescription Refills",
    "Health Education",
    "Limited Follow-up Support"
  ] : [
    "Remote Consultations",
    "Prescription Refills",
    "Health Education",
    "Limited Follow-up Support"
  ] 
  return (
    <div
      className={cn("pricing-box wow", item.wowClass)}
      data-wow-delay={item.delay + "s"}
    >
      <div className="pricing-top">
        {/* <div className="pricing-icon">{item?.icon}</div> */}
        <div className="pricing-icon">{index === 0 ?<FaGift /> : index === 1 ? <AiFillThunderbolt />: <FaMedal />} </div>
        <h6 className="pricing-plan">{item?.title}</h6>
        <h2 className="pricing-price">${item?.package_tiers?.[0]?.yearly_retail_price}</h2>
      </div>

      <div className="pricing-bottom">
        <div className="pricing-list">
          <ul className="list-wrap">
            {facilities.map((x, index) => (
              <li key={index}>
                <i className="fas fa-check" />
                {x}
              </li>
            ))}
          </ul>
        </div>

        <div className="pricing-btn">
          <Link to="/packages" className="btn">
            Choose Plan
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PricingItem;
