import React from "react";

const TeamDetailsSidebar = ({data}) => {
  return (
    <div className="team-details-img">
      <img src="/img/telehealth/doctor3.jpg" alt="" />
      <div className="team-details-info">
        <h2 className="title">{data?.first_name} {" "} {data?.last_name}</h2>
        <span>{data?.speciality}</span>
        {/* <div className="team-details-social">
          <ul className="list-wrap">
            <li>
              <a href="/">
                <i className="fab fa-facebook-f"></i>
              </a>
            </li>
            <li>
              <a href="/">
                <i className="fab fa-twitter"></i>
              </a>
            </li>
            <li>
              <a href="/">
                <i className="fab fa-linkedin-in"></i>
              </a>
            </li>
            <li>
              <a href="/">
                <i className="fab fa-instagram"></i>
              </a>
            </li>
          </ul>
        </div> */}

        <div className="info-list">
          <ul className="list-wrap">
            {/* <li>
              Website : <a href="#!">yourwebsite.com</a>
            </li> */}
            {/* <li>
              Phone : <a href="tel:0123456789">{data?.phone_number}</a>
            </li>
            <li>
              Email : <a href="mailto:custom@gmail.com">{data?.email}</a>
            </li> */}
            <li>
              Address : <span>380 Albert St, Melbourne</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default TeamDetailsSidebar;
