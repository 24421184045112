import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import cn from "classnames";
 import { API } from "../../config/urls";
 import  axios  from "axios";

const HeaderNavMenu = ({setLoading}) => {
  const { pathname } = useLocation();

  const isActiveClassName = (path) => {
    return path === pathname ? "active" : "";
  };

  const [data,setData] = useState()
  

  useEffect(() => {
    const Getnav = () => {
      var config = {
        method: "get",
        url: API.getheader,
        headers: {},
      };
      axios(config)
        .then(function (response) {
          setData(response.data?.[0]);
          setLoading(false)
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    Getnav();
  }, [pathname, setLoading]);

  return (
    <div className="navbar-wrap main-menu d-none d-lg-flex">
      <ul className="navigation">
        {data && data.contents.map((x) => (
          <li
            key={x.id}
            style={{cursor: "pointer"}}
            className={cn("menu-item-has-children", {
              active: x.content.some((d) =>
                pathname.includes(d.title.toLowerCase().replace(/\s/g, ""))
              ),
            })}
          >
            <div>
              {x.name}
            </div>
            <ul className="sub-menu">
              {x.content.map((d) => (
                <li key={d.id} className={cn(isActiveClassName(d.title))}>
                  <Link to={"/"+d.title.toLowerCase().replace(/\s/g, "-")}>
                    {d.title}
                  </Link>
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default HeaderNavMenu;
