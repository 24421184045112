import React, { useState } from "react";
import FooterOne from "../components/Footers/FooterOne";
import FooterTwo from "../components/Footers/FooterTwo";
import HeaderOne from "../components/Headers/HeaderOne";
import HeaderThree from "../components/Headers/HeaderThree";
import HeaderTwo from "../components/Headers/HeaderTwo";
import PageLoader from "../components/PageLoader/PageLoader";
import ScrollToTopButton from "../components/ScrollToTopButton/ScrollToTopButton";

const Layout = ({
  children,
  header = 1,
  footer = 1,
  topHeaderClassName,
  headerClassName,
}) => {
  const [loading,setLoading] = useState(true)
  console.log(loading);
  return (
    <>
      <PageLoader loading={loading} />
      <ScrollToTopButton />

      {/* header */}
      {header === 1 && (
        <HeaderOne
          headerClassName={headerClassName}
          topHeaderClassName={topHeaderClassName}
          setLoading={setLoading}
        />
      )}

      {header === 2 && (
        <HeaderTwo
          headerClassName={headerClassName}
          topHeaderClassName={topHeaderClassName}
          setLoading={setLoading}
        />
      )}
      {header === 3 && <HeaderThree />}

      {/* main content */}
      <main>{children}</main>
      {/* <main>{React.cloneElement(children, { loading, setLoading })}</main> */}


      {/* footer */}
      {footer === 1 && <FooterOne />}
      {footer === 2 && <FooterTwo />}
    </>
  );
};

export default Layout;
