import React from "react";
import { Stripepage } from "./paymentform";
import { useContext } from "react";
import { Packages } from "../../main";

export default function Payment(props) {
  const [state] = useContext(Packages);
  // const navigate = useNavigate();
  return (
    <div>
      <div className="row text-dark fs-6 bg-light mx-3 py-3">
        <div className="w-100 lead fs-6 text-start">
          Your first Payment of{" "}
          <strong className="fs-5 fw-bold">
            $
            {state.terms === "yearly" ? (
              <>{state.package_tiers?.[0]?.yearly_retail_price.toFixed(2)}</>
            ) : (
              <>{(state.package_tiers?.[0]?.yearly_retail_price / 12).toFixed(2)}</>
            )}
          </strong>{" "}
          will be charged today, {new Date().toLocaleDateString()}.{" "}
          <strong>
            You are buying <strong className="fs-5">{state.title}</strong> for{" "}
            <strong className="fs-5">
              $
              {state.terms === "yearly" ? (
                <>{state.package_tiers?.[0]?.yearly_retail_price.toFixed(2)}</>
              ) : (
                <>{(state.package_tiers?.[0]?.yearly_retail_price / 12).toFixed(2)}</>
              )}
            </strong>{" "}
            / {state.terms === "yearly" ? "Yearly" : "Monthly"}. By clicking 'Buy
            Subscription' you agree to our{" "}
            <span className="greentxt text-decoration-none" style={{cursor: "pointer"}}>Terms of Service.</span>{" "}
          </strong>
        </div>
      </div>
      <div>
        <Stripepage publishkey={props.promise} />
      </div>
    </div>
  );
}
