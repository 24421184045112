import React, { useState, useMemo } from "react";
import TeamOneItem from "./TeamOneItem";

const TeamAll = ({ data }) => {
  const [keyword, setKeyword] = useState("");
  const [selectedSpeciality, setSelectedSpeciality] = useState("");

  // Create an array of unique speciality options
  const specialityOptions = useMemo(() => {
    const specialities = data?.map((item) => item.speciality);
    return Array.from(new Set(specialities)).map((speciality) => ({
      value: speciality,
      label: speciality,
    }));
  }, [data]);

  // Filter the data based on keyword and selected speciality
  const filteredData = data?.filter((item) => {
    const matchesKeyword = item.first_name
      .toLowerCase()
      .includes(keyword.toLowerCase());
    const matchesSpeciality = selectedSpeciality
      ? item.speciality === selectedSpeciality
      : true;
    return matchesKeyword && matchesSpeciality;
  });

  return (
    <section className="team-area pb-100">
      <div className="flex flex-row search-filter">
        <div className="row w-100">
          <div className="col-md-4 mb-10">
            <input
              type="search"
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              id="table-search"
              placeholder="Search by Name"
              className="form-control search-input cursor-default"
            />
          </div>
          <div className="col-md-4 mb-10">
            <input
              type="search"
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              id="table-search"
              placeholder="Search by Location"
              className="form-control search-input cursor-default"
            />
          </div>
          <div className="col-md-4 mb-10">
            <select
              value={selectedSpeciality}
              onChange={(e) => setSelectedSpeciality(e.target.value)}
              className="form-control speciality-select"
            >
              <option value="">All Specialities</option>
              {specialityOptions?.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      <div className="container mt-5">
        <div className="row gap-3">
          {filteredData?.map((x, index) => (
            <div key={index} className="col-lg-12 col-md-6 col-sm-9">
              <TeamOneItem item={x} index={index} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default TeamAll;
