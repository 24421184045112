import React from "react";
import { MdHealthAndSafety } from "react-icons/md";
import { Link } from "react-router-dom";


const ServicesAreaTwoItem = ({ item }) => {
  return (
    <div className="services-item-two wow fadeInUp" data-wow-delay=".2s">
      <div className="services-icon-two"><MdHealthAndSafety /></div>

      <div className="services-content-two">
        <h2 className="title">
          <Link to="/">{item?.heading}</Link>
        </h2>

        <div
          className=" fs-6"
          dangerouslySetInnerHTML={{ __html: item?.description?.replace(/<[^>]+>/g, '')?.slice(0,200) }}
        ></div>
      </div>
    </div>
  );
};

export default ServicesAreaTwoItem;
