import React from "react";
// import { Link } from "react-router-dom";
// import { imgconvert } from "../../helpers/imgconverter";
import { MdHealthAndSafety } from "react-icons/md";

const ServicesAreaThreeItem = ({ item }) => {
  return (
    <div className="services-item-three">
      <div className="services-thumb-three">
        {/* <Link to={item.url}> */}
        {/* <img src={imgconvert(item?.image?.[0]?.image)} alt=""/> */}
        <img src={"/img/telehealth/convinient.jpg"} alt="" />
        {/* </Link> */}
      </div>

      <div className="services-content-three">
        <div className="icon">
          <MdHealthAndSafety />
        </div>

        <h2 className="title">
          {/* <Link to={item.url}>{item.heading}</Link> */}
          {item.heading}
        </h2>

        <p>
          <div
            style={{ lineHeight: "2.5rem" }}
            className="h6 opacity-75 text-start"
            dangerouslySetInnerHTML={{
              __html: item && item.description?.slice(0,200),
            }}
          ></div>
        </p>

        {/* <Link to={item.url} className="btn btn-two">
          Read more
        </Link> */}
      </div>
    </div>
  );
};

export default ServicesAreaThreeItem;
