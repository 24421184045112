import React from "react";
import { useContext } from "react";
import { Packages } from "../../main";
import Payment from "./payment";
// import Payreview from "./payreview";
import { MdCancel } from "react-icons/md";

export default function Paymentmain(props) {
  const [state, actions] = useContext(Packages);
  // const [payreview,setPayReview] = useState(true)
  // const [disabled, setdisabled] = useState(false);
console.log(state);
  return (
    <div
      style={{ backgroundColor: "rgba(0, 0, 0, 0.7)", zIndex: "100" }}
      className=" w-100 h-100  position-fixed top-0 start-0"
    >
      <div
        style={{
          top: "4%",
          left: 0,
          right: 0,
          bottom: 0,
        }}
        className=" position-fixed"
      >
        <div
          style={{ width: "100%", maxWidth: "600px" }}
          className=" container p-0 bg-light text-white fs-5 fullround"
        >
          <div className="greenbg row m-0 w-100 px-3 py-3 roundstart">
            <div className="col fs-5 fw-bold"> Make A Payment</div>
            <div className="col-1 mr-4">
              <button
                onClick={() => {
                  actions({ ...state, ...{ paymentdisplay: true, page: "1" } });
                }}
                className="border-0"
                style={{ borderRadius: "10px", color: "#1239ac", paddingLeft: "10px", paddingRight: "10px"}}
              >
                {/* <i class="bi bi-x-circle bg-none text-white"></i> */}
                <MdCancel />
              </button>
            </div>
          </div>
          {/* {state.page === "1" ? ( */}
          {/* {payreview ? (
            <Payreview setPayReview={setPayReview} />
          ) : ( */}
            <Payment promise={props.promise} />
          {/* )} */}
        </div>
      </div>
    </div>
  );
}
