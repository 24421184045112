import React from "react";

const TestimonialAreaTwoItem = ({ item }) => {
  return (
    <div className="testimonial-item-two">
      <div className="testimonial-icon-two">
        <i className="fas fa-quote-right"></i>
      </div>

      <div className="testimonial-content-two">
        <p>
          <div
            style={{ lineHeight: "2.5rem" }}
            className="h6 opacity-75 text-start"
            dangerouslySetInnerHTML={{
              __html: item && item.description,
            }}
          ></div>
        </p>

        <div className="testimonial-avatar-info">
          <div className="avatar-thumb">
            <img src={"/img/images/avatar_img01.png"} alt="" />
          </div>

          <div className="avatar-content">
            <h4 className="title">{item.heading}</h4>

            <p>{item.sub_heading}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialAreaTwoItem;
