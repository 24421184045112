import { createStore, StateMachineProvider } from "little-state-machine";
import React, { createContext, useState } from "react";
import { Route, Routes } from "react-router-dom";
import Step1 from "./steps/Step1";
import "./steps/style/styles.css";
import Layout from "../../layouts/Layout";
import Step2 from "./steps/Step2";
import Result from "./steps/Result";

createStore({});
export const Packages = createContext([
  {
    package_tiers: [{}],
  },
]);

function Check() {
  const [contextstate, setcontextstate] = useState({
    // title: "",
    // paymentdisplay: true,
    // page: "1",
    terms: "yearly",
    change_my_plan: false
    // del_keys: null,
  });

  return (
    <Layout header={2} footer={1}>
    <div className="bg1 p-2 py-5">
      <StateMachineProvider>
        <Packages.Provider value={[contextstate, setcontextstate]}>
          <div style={{ maxWidth: 1000 }} className="pb-5 container-md">
            <Routes>
              <Route path="/" element={<Step1 />} />
              <Route path="step2" element={<Step2 />} />
              <Route path="results" element={<Result />} /> 
            </Routes>
          </div>
        </Packages.Provider>
      </StateMachineProvider>
    </div>
    </Layout>
  );
}

export default Check;
