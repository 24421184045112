import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
// import { message } from "antd";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom"; 
import { Packages } from "../../main";
import "./style.css";
import { localURL } from "../../../../config/urls";
import axios from "axios"

const CARD_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#4a4a4a",
      color: "#4a4a4a",
      fontWeight: 500,
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "16px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": {
        color: "#4a4a4a",
      },
      "::placeholder": {
        color: "#4a4a4a",
      },
    },
    invalid: {
      iconColor: "#ff3737",
      color: "#ff3737",
    },
  },
};

const CardField = ({ onChange }) => (
  <div className="FormRow">
    <CardElement options={CARD_OPTIONS} onChange={onChange} />
  </div>
);

// const Field = ({
//   label,
//   id,
//   type,
//   placeholder,
//   required,
//   autoComplete,
//   value,
//   onChange,
// }) => (
//   <div className="FormRow">
//     <input
//       className="FormRowInput"
//       id={id}
//       type={type}
//       placeholder={placeholder}
//       required={required}
//       autoComplete={autoComplete}
//       value={value}
//       onChange={onChange}
//     />
//   </div>
// );

const SubmitButton = ({ processing, error, children, disabled }) => (
  <button
    className={`SubmitButton ${error ? "SubmitButton--error" : ""}`}
    type="submit"
    disabled={processing || disabled}
  >
    {processing ? "Processing..." : children}
  </button>
);

const ErrorMessage = ({ children }) => (
  <div className="ErrorMessage" role="alert">
    {children}
  </div>
);

const CheckoutForm = (props) => {
  const stripe = useStripe();
  const navigate = useNavigate();
  const [state] = useContext(Packages);
  const elements = useElements();
  const [error, setError] = useState(null);
  const [cardComplete, setCardComplete] = useState(false);
  const [processing, setProcessing] = useState(false);
  // const [paymentMethod, setPaymentMethod] = useState(null);

  // const [billingDetails, setBillingDetails] = useState({
  //   email: "",
  //   name: "",
  //   Address: "",
  // });

  const handleSubmit = async (event) => {
    setProcessing(true);
    event.preventDefault();
    const postdata = (propsdata) => {
      if (propsdata.error) {
        setProcessing(false);
        setError(propsdata.error);
        return;
      }
      // const axios = require("axios");
      let data = JSON.stringify({
        email: props.state2.Email,
        first_name: props.state2.Firstname,
        middle_name: props.state2.middle_name,
        last_name: props.state2.Lastname,
        gender: props.state2.gender === "Male" ? "M" : "F",
        dob: props.state2.Dob,
        // dob: moment(props.state2.Dob).format("MM-DD-YYYY"),
        address_1: props.state2.Address,
        address_2: props.state2.address_2,
        zip_code: props.state2.Zip,
        city: props.state2.City,
        state: props.state2.State,
        language: props.state2.language === "English" ? "ENGLISH" : "SPANISH",
        phone_number: props.state2.Mobileno,
        package: props.state2.id,
        recurring_payment: true,
        individual_relationship_code: 1,
        package_terms: props.state2.terms === "monthly" ? "M" : props.state2.terms === "yearly" ? "Y" : "O",
        stripeToken: propsdata.token.id,
        payment_type: "Card",
        include_dependents: false,
        // del_keys: window.localStorage.getItem("del_keys"),
      });

      let config = {
        method: "post",
        url: localURL + "/api/v1/member/signup/",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        data: data,
      };
      console.log(config.data);
      axios(config)
        .then((response) => {
          navigate("/account-created");
        })
        .catch((error) => {
          setProcessing(false);
          console.log(error);
          function ifcarderror() {
            const x = error.response.data[0];
            let y = x.search(":");
            console.log(x.slice(y + 1));
            // message.error(x.slice(y + 1));
          }
          function unactive() {
            console.log("There was an error.");
            // message.error("There was an error.");
          }
          typeof error.response.data[0] === `string`
            ? ifcarderror()
            : unactive();
        });
    };
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    const card = elements.getElement(CardElement);

    if (card == null) {
      return;
    }

    if (error) {
      card.focus();
      return;
    }

    if (cardComplete) {
    }

    const cardElement1 = elements.getElement("card");

    stripe.createToken(cardElement1).then((payload) => {
      setProcessing(true);
      postdata(payload);
    });
  };

  return (
    <form className="Form" onSubmit={handleSubmit}>
      <fieldset className="FormGroup pt-2 py-0">
        <CardField
          onChange={(e) => {
            setError(e.error);
            setCardComplete(e.complete);
          }}
        />
        <div>{error && <ErrorMessage>{error.message}</ErrorMessage>}</div>
      </fieldset>

      <SubmitButton processing={processing} error={error} disabled={!stripe}>
        Pay $
        {state.terms === "yearly" ? (
          <>{state.package_tiers?.[0]?.yearly_retail_price.toFixed(2)}</>
        ) : (
          <>{(state.package_tiers?.[0]?.yearly_retail_price / 12).toFixed(2)}</>
        )}
      </SubmitButton>
      {/* <div style={{ height: 60 }} className="container ">
        <button
          onClick={() => {
            state.page = "2";
          }}
          className="btn float-start editplanbtn rounded-pill mx-2"
        >
          Previous
        </button>
      </div> */}
    </form>
  );
};

const ELEMENTS_OPTIONS = {};

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

export const Stripepage = (props) => {
  const stripePromise = loadStripe(props.publishkey);
  const [state] = useContext(Packages);
  console.log(props.publishkey);
  return (
    <div className="AppWrapper p-5">
      <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
        <CheckoutForm
          state2={state}
          payprice={state.package_tiers?.[0]?.yearly_retail_price}
        />
      </Elements>
    </div>
  );
};
